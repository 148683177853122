.chat-transcript-container{
    max-height: 600px;
    overflow-y: scroll;
}

.chat-bot{
    text-align: left;
    padding: 5px 7px;
    background: #f1f1f1;
    border-radius: 5px;
    max-width: 200px;
    margin-right: auto;
    margin-bottom: 15px;
}

.chat-user{
    text-align: right;
    padding: 5px 7px;
    background: #2898ec;
    border-radius: 5px;
    color: #f1f1f1;
    max-width: 200px;
    margin-left: auto;
    margin-bottom: 15px;
}
code {
    background: hsl(0, 0%, 89%);
}

pre {
    white-space: pre-wrap;
    background: hsl(0, 0%, 89%);
}
